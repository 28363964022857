<template>
  <div id="rebate-payment-history" class="rebate-payment-history">
    <div class="wrapper-main">
      <div class="wrapper-content">
        <AccountNumber ref="accountNumber" @accountCallback="init"></AccountNumber>

        <div class="calendar_content" v-if="updateTimeVisible">
          <DateRangePicker v-bind:start-date.sync="startDate" v-bind:end-date.sync="endDate"></DateRangePicker>
          <div class="right_box">
            <el-button class="btn-red" @click="setStartAndEndDate" data-testid="UPDATE">{{
              $t('home.UPDATE')
            }}</el-button>
          </div>
        </div>

        <Rebate ref="rebate" :userID="userID" @accountIdAndCurrency="getAccountIdAndCurrency"> </Rebate>

        <div class="card">
          <div class="tab-explain">
            <div>
              {{ $t('rebatePaymentHistory.rebatePaymentHistory') }} {{ $t('common.keys.FROM') }}
              <span class="blue">
                {{ startDateDisplay | formatDate('DD/MM/YYYY') }} -
                {{ endDateDisplay | formatDate('DD/MM/YYYY') }}
              </span>
              &nbsp;>>>
            </div>
            <download-button
              class="button"
              :callback="downLoad"
              icon="el-icon-download"
              :noPermission="!fundsPermission"
            >
            </download-button>
          </div>
          <div class="table-content">
            <ul class="tab-demo">
              <li
                :class="{ active: active === 'rebateHistory' }"
                @click="active = 'rebateHistory'"
                data-testid="rebateHistory"
              >
                {{ $t('rebatePaymentHistory.rebateHistory') }}
              </li>
              <li
                :class="{ active: active === 'withdrawHistory' }"
                @click="active = 'withdrawHistory'"
                data-testid="withdrawHistory"
              >
                {{ $t('rebatePaymentHistory.withdrawHistory') }}
              </li>
              <li
                :class="{ active: active === 'transferHistory' }"
                @click="active = 'transferHistory'"
                data-testid="transferHistory"
              >
                {{ $t('rebatePaymentHistory.transferHistory') }}
              </li>
            </ul>
            <ul class="table-box-item">
              <li :class="{ active: active === 'rebateHistory' }">
                <div class="table-box">
                  <el-table :data="rebateHistoryData.rows" style="width: 100%;">
                    <el-table-column prop="date" :label="$t('common.keys.DATE')" min-width="120"> </el-table-column>
                    <el-table-column prop="rangeDate" :label="$t('common.keys.DATERANGE')" min-width="120">
                    </el-table-column>
                    <el-table-column prop="amount" :label="$t('common.keys.amount').toUpperCase()" min-width="120">
                      <template slot-scope="rebateTable">
                        {{ currAccountCurrency | currencySymbol }}{{ rebateTable.row.amount | currency('') }}
                      </template>
                    </el-table-column>
                    <el-table-column prop="status" :label="$t('common.keys.STATUS')" min-width="120">
                      <template slot-scope="scope">
                        <div :class="scope.row.status === 4 ? 'red' : 'gray'">
                          {{ $t(rebateStatusDesc(scope.row.status)) }}
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
                <div class="page-box clearfix">
                  <el-pagination
                    :background="true"
                    :page-size="25"
                    layout="total, sizes, prev, pager, next"
                    :page-sizes="[25, 50, 100]"
                    :total="rebateHistoryData.total"
                    @current-change="handleRebateCurrentChange"
                    @size-change="handleRebateSizeChange"
                    :current-page.sync="rebatePageNo"
                  >
                  </el-pagination>
                </div>
              </li>
              <li :class="{ active: active === 'withdrawHistory' }">
                <div class="table-box">
                  <el-table :data="withdrawHistoryData.rows" style="width: 100%;">
                    <el-table-column prop="date" :label="$t('common.keys.DATE')" min-width="120"> </el-table-column>
                    <el-table-column prop="amount" :label="$t('common.keys.amount').toUpperCase()" min-width="120">
                      <template slot-scope="withdrawTable">
                        {{ currAccountCurrency | currencySymbol }}{{ withdrawTable.row.amount | currency('') }}
                      </template>
                    </el-table-column>
                    <el-table-column prop="destination" :label="$t('common.keys.DEST')" min-width="150">
                      <template slot-scope="scope">
                        {{ $t(withdrawTypeDesc(scope.row.destination)) }}
                      </template>
                    </el-table-column>
                    <el-table-column prop="status" :label="$t('common.keys.STATUS')" min-width="120">
                      <template slot-scope="scope">
                        <div :class="getWithdrawStatusColor(scope.row.status)">
                          {{ $t(getWithdrawStatus(scope.row.status, scope.row.processedByOp)) }}
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column v-if="showCancelWithdraw" min-width="120">
                      <template slot-scope="scope">
                        <div>
                          <el-button
                            v-if="showCancelButton(scope.row)"
                            @click="cancelWithdraw(scope.row)"
                            class="btn-blue cancel-btn"
                          >
                            {{ $t('common.button.cancel') }}
                          </el-button>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
                <div class="page-box clearfix">
                  <el-pagination
                    :background="true"
                    :page-size="25"
                    layout="total, sizes, prev, pager, next"
                    :total="withdrawHistoryData.total"
                    :page-sizes="[25, 50, 100]"
                    @current-change="handleWithdrawCurrentChange"
                    @size-change="handleWithdrawSizeChange"
                    :current-page.sync="withdrawPageNo"
                  >
                  </el-pagination>
                </div>
              </li>
              <li :class="{ active: active === 'transferHistory' }">
                <div class="table-box">
                  <el-table :data="transferHistoryData.rows" style="width: 100%;">
                    <el-table-column :label="$t('common.keys.DATE')">
                      <template slot-scope="scope">{{ scope.row.date | date }}</template>
                    </el-table-column>
                    <el-table-column :label="$t('common.keys.FROM')">
                      <template slot-scope="scope">{{
                        scope.row.fromAccount + ' (' + scope.row.from_currency + ')'
                      }}</template>
                    </el-table-column>
                    <el-table-column :label="$t('common.keys.TO')">
                      <template slot-scope="scope">{{
                        scope.row.toAccount + ' (' + scope.row.to_currency + ')'
                      }}</template>
                    </el-table-column>
                    <el-table-column prop="amount" :label="$t('common.keys.amount').toUpperCase()">
                      <template slot-scope="transferTable">
                        <span v-if="transferTable.row.from_currency === transferTable.row.to_currency">
                          {{ currAccountCurrency | currencySymbol }} {{ transferTable.row.amount | formatNumber }}
                        </span>
                        <span v-else
                          >{{ transferTable.row.from_currency | currencySymbol
                          }}{{ transferTable.row.amount | formatNumber }}->{{
                            transferTable.row.to_currency | currencySymbol
                          }}{{ transferTable.row.toAmount | formatNumber }}</span
                        >
                      </template>
                    </el-table-column>
                    <el-table-column :label="$t('common.keys.STATUS')">
                      <template slot-scope="scope">{{ scope.row.status | payStatus }}</template>
                    </el-table-column>
                  </el-table>
                </div>
                <div class="page-box clearfix">
                  <el-pagination
                    :background="true"
                    :page-size="25"
                    layout="total, sizes, prev, pager, next"
                    :total="transferHistoryData.total"
                    :page-sizes="[25, 50, 100]"
                    @current-change="handleTransferCurrentChange"
                    @size-change="handleTransferSizeChange"
                    :current-page.sync="transferPageNo"
                  >
                  </el-pagination>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <!-- cancel withdrawl dialog -->
        <vDialog v-if="dialogVisible" :show.sync="dialogVisible">
          <div>
            <h5 class="cancelwithdraw-content">{{ $t('rebatePaymentHistory.cancelWithdraw') }}</h5>
            <div class="cancelwithdraw-actions">
              <el-button plain @click="closeDialog" class="text-uppercase btn-default">{{
                $t('common.field.no')
              }}</el-button>
              <el-button @click="submitCancelWithdraw" class="text-uppercase btn-blue">{{
                $t('common.field.yes')
              }}</el-button>
            </div>
          </div>
        </vDialog>
        <downloadDialog
          v-if="downloadVisible"
          :downloadVisible.sync="downloadVisible"
          @closeDownloadDoalog="closeDownloadDoalog"
          @onConfrim="onConfrim"
        ></downloadDialog>
      </div>
    </div>
  </div>
</template>

<script>
import Rebate from '@/components/Rebate';
import AccountNumber from '@/components/form/AccountNumber';
import DateRangePicker from '@/components/DateRangePicker';
import vDialog from '@/components/vDialog';
import { apiRebate, apiCancelWithdraw } from '@/resource';
import DownloadButton from '@/components/download/DownloadButton';
import { downLoadExcelMode } from '@/util/downLoadExcelMode';
import downloadDialog from '@/components/download/Dialog';

export default {
  data() {
    return {
      downloadVisible: false,
      updateTimeVisible: true,
      userID: this.$store.state.common.CUID,
      startDate: '',
      endDate: '',
      startDateDisplay: '',
      endDateDisplay: '',
      accountID: '',
      currAccountCurrency: '',
      rebatePageNo: 1,
      rebatePageSize: 25,
      withdrawPageNo: 1,
      withdrawPageSize: 25,
      transferPageNo: 1,
      transferPageSize: 25,
      rebateHistoryData: { rows: [], total: 0 },
      withdrawHistoryData: {
        rows: [],
        total: 0
      },
      transferHistoryData: {
        rows: [],
        total: 0
      },
      active: 'rebateHistory',
      cancelInfo: null,
      dialogVisible: false,
      ngaCountryCode: 5796
    };
  },
  methods: {
    closeDownloadDoalog() {
      this.updateTimeVisible = true;
      this.downloadVisible = false;
    },
    async onConfrim(startDate, endDate, isUpdate) {
      if (isUpdate) this.updateTimeVisible = false;
      this.startDate = startDate;
      this.endDate = endDate;
      this.startDateDisplay = startDate;
      this.endDateDisplay = endDate;
      this.initHistory();
      const downloadFile = await this.downLoadExcel();
      if (downloadFile) {
        this.$message({
          message: this.$t('ibReport.successInfo'),
          type: 'success'
        });
      } else {
        this.$message({
          message: '',
          type: 'error'
        });
      }
      if (isUpdate) this.closeDownloadDoalog();
    },
    async downLoad() {
      // 间隔天数
      let days = this.$options.filters.formatDateDiff(this.startDate, this.endDate);
      // 允许下载最大天数
      const MAX_DAYS = 90;
      if (days > MAX_DAYS) {
        //大于90天弹框 重新选择日期再下载
        this.downloadVisible = true;
        return Promise.resolve();
      }
      //在90天范围内直接下载excel
      await this.onConfrim(this.startDate, this.endDate);
    },
    async downLoadExcel() {
      // 表名称
      const fileName = `${this.accountID}-${this.$t('rebatePaymentHistory.rebatePaymentHistory')}${this.timeFrame}`;
      // sheet 表头
      const rebateHistoryHeader = [
        'common.keys.DATE',
        'common.keys.DATERANGE',
        'common.keys.amount',
        'common.keys.STATUS'
      ].map(key => {
        return key === 'common.keys.amount' ? this.$t(key).toUpperCase() : this.$t(key);
      });
      const withdrawHistoryHeader = [
        'common.keys.DATE',
        'common.keys.amount',
        'common.keys.DEST',
        'common.keys.STATUS'
      ].map(key => {
        return key === 'common.keys.amount' ? this.$t(key).toUpperCase() : this.$t(key);
      });
      const transferHistoryHeader = [
        'common.keys.DATE',
        'common.keys.FROM',
        'common.keys.TO',
        'common.keys.amount',
        'common.keys.STATUS'
      ].map(key => {
        return key === 'common.keys.amount' ? this.$t(key).toUpperCase() : this.$t(key);
      });
      // sheet名称
      const rebateHistoryName = `${this.$t('rebatePaymentHistory.rebateHistory')}`;
      const withdrawHistoryName = `${this.$t('rebatePaymentHistory.withdrawHistory')}`;
      const transferHistoryName = `${this.$t('rebatePaymentHistory.transferHistory')}`;

      const rebateHistory = await this.downlaodingTradeReport(0);
      const withdrawHistory = await this.downlaodingTradeReport(1);
      const tradeHistory = await this.downlaodingTradeReport(2);

      const rebateHistoryData = {
        sheetData: rebateHistory,
        // sheetData: this.parseExcelRebateHistoryData(this.rebateHistoryData.rows||[]),
        sheetName: rebateHistoryName.slice(0, 31),
        sheetHeader: rebateHistoryHeader,
        columnWidths: [5, 7, 6, 8, 7, 8] //宽度
      };
      const withdrawHistoryData = {
        sheetData: withdrawHistory,
        // sheetData: this.parseExcelWithdrawHistoryData(this.withdrawHistoryData.rows||[]),
        sheetName: withdrawHistoryName.slice(0, 31),
        sheetHeader: withdrawHistoryHeader,
        columnWidths: [5, 7, 6, 8, 7, 8]
      };
      const transferHistoryData = {
        sheetData: tradeHistory,
        // sheetData: this.parseExcelTransferHistoryData(this.transferHistoryData.rows||[]),
        sheetName: transferHistoryName.slice(0, 31),
        sheetHeader: transferHistoryHeader,
        columnWidths: [5, 7, 6, 8, 7, 8]
      };
      let datas = [rebateHistoryData, withdrawHistoryData, transferHistoryData];

      if (datas.length) return await downLoadExcelMode(fileName, datas);
    },
    //获取多列详情数据
    async queryAllTradeDetails(type, currentPage = 1, currentPageSize = 500) {
      let urls = ['rebateHistory', 'withdrawHistory', 'transferHistory'];
      return apiRebate(urls[type], {
        pageNo: currentPage,
        limit: currentPageSize,
        qUserId: this.userID,
        qAccount: this.accountID,
        qStartTime: this.startDate,
        qEndTime: this.endDate
      }).then(resp => {
        if (resp.data.code === 0) {
          return resp.data.data || [];
        }
      }, this.$handleError);
    },
    async downlaodingTradeReport(type) {
      let promises = [[], [], []];
      const recordsNum = 500;
      let tradeHistory = [];
      let pageNumber = [1, 1, 1];
      let pageTotal = [this.rebateHistoryData.total, this.withdrawHistoryData.total, this.transferHistoryData.total];

      do {
        let tradeDetail = this.queryAllTradeDetails(type, pageNumber[type], recordsNum);
        promises[type].push(tradeDetail);
        pageNumber[type]++;
      } while (pageNumber[type] < pageTotal[type] / recordsNum + 1);

      await Promise.all(promises[type])
        .then(results => {
          if (results.length > 0) {
            results.map(item => {
              tradeHistory = tradeHistory.concat(item);
            });
          }
        })
        .catch(error => {
          console.error('At least one promise rejected:', error);
        });

      switch (type) {
        case 0:
          return this.parseExcelRebateHistoryData(tradeHistory);
        case 1:
          return this.parseExcelWithdrawHistoryData(tradeHistory);
        case 2:
          return this.parseExcelTransferHistoryData(tradeHistory);
      }
    },
    parseExcelRebateHistoryData(traderTableData) {
      return traderTableData.map(item => {
        const { date, rangeDate, amount, status } = item;
        return {
          date: this.$options.filters.date(date),
          rangeDate,
          amount: this.$options.filters.currencySymbol(this.currAccountCurrency) + amount,
          status: this.$t(this.rebateStatusDesc(status))
        };
      });
    },
    parseExcelWithdrawHistoryData(traderTableData) {
      return traderTableData.map(item => {
        const { date, amount, status, destination, processedByOp } = item;
        return {
          date: this.$options.filters.date(date),
          amount: this.$options.filters.currencySymbol(this.currAccountCurrency) + amount,
          DEST: this.$t(this.withdrawTypeDesc(destination)),
          status: this.$t(this.getWithdrawStatus(status, processedByOp))
        };
      });
    },
    parseExcelTransferHistoryData(traderTableData) {
      return traderTableData.map(item => {
        const { date, amount, status, fromAccount, from_currency, toAccount, to_currency, toAmount } = item;
        return {
          date: this.$options.filters.date(date),
          from: `${fromAccount} (${from_currency})`,
          to: `${toAccount} (${to_currency})`,
          amount:
            from_currency === to_currency
              ? this.$options.filters.currencySymbol(this.currAccountCurrency) + amount
              : `${this.$options.filters.currencySymbol(from_currency)} ${this.$options.filters.formatNumber(
                  amount
                )} -> ${this.$options.filters.currencySymbol(to_currency)} ${this.$options.filters.formatNumber(
                  toAmount
                )}`,
          status: this.$options.filters.payStatus(status)
        };
      });
    },
    init() {
      this.$refs.rebate.init();
    },
    setStartAndEndDate() {
      this.startDateDisplay = this.startDate;
      this.endDateDisplay = this.endDate;
      this.$nextTick(() => this.initHistory());
    },
    //分页
    handleRebateCurrentChange(pageNo) {
      this.rebatePageNo = pageNo;
      this.$nextTick(() => this.queryRebateHistory(0));
    },
    handleRebateSizeChange(size) {
      this.rebatePageSize = size;
      this.$nextTick(() => this.queryRebateHistory(0));
    },
    handleWithdrawCurrentChange(pageNo) {
      this.withdrawPageNo = pageNo;
      this.$nextTick(() => this.queryRebateHistory(1));
    },
    handleWithdrawSizeChange(size) {
      this.withdrawPageSize = size;
      this.$nextTick(() => this.queryRebateHistory(1));
    },
    handleTransferCurrentChange(pageNo) {
      this.transferPageNo = pageNo;
      this.$nextTick(() => this.queryRebateHistory(2));
    },
    handleTransferSizeChange(size) {
      this.transferPageSize = size;
      this.$nextTick(() => this.queryRebateHistory(2));
    },
    initHistory() {
      this.queryRebateHistory(0);
      this.queryRebateHistory(1);
      this.queryRebateHistory(2);
    },
    queryRebateHistory(type) {
      if (!this.accountID) {
        return;
      }

      let urls = ['rebateHistory', 'withdrawHistory', 'transferHistory'];
      let pageNo = [this.rebatePageNo, this.withdrawPageNo, this.transferPageNo];
      let pageSize = [this.rebatePageSize, this.withdrawPageSize, this.transferPageSize];
      apiRebate(urls[type], {
        pageNo: pageNo[type],
        limit: pageSize[type],
        qUserId: this.userID,
        qAccount: this.accountID,
        qStartTime: this.startDate,
        qEndTime: this.endDate
      }).then(resp => {
        if (resp.data.code === 0) {
          if (type === 0) {
            this.rebateHistoryData.rows = resp.data.data;
            this.rebateHistoryData.total = resp.data.totalRecords;
          } else if (type === 1) {
            this.withdrawHistoryData.rows = resp.data.data;
            this.withdrawHistoryData.total = resp.data.totalRecords;
          } else {
            this.transferHistoryData.rows = resp.data.data;
            this.transferHistoryData.total = resp.data.totalRecords;
          }
        }
      }, this.$handleError);
    },
    getAccountIdAndCurrency(accountID, currency) {
      this.accountID = accountID;
      this.currAccountCurrency = currency;
      this.$nextTick(() => this.initHistory());
    },
    showCancelButton(item) {
      // 20220811 UninoPay not support CancelWithdraw (method: 4)
      const { status, processedByOp, destination } = item;
      return !processedByOp && (status === 1 || status === 3 || status === 5) && destination !== 4;
    },
    cancelWithdraw(info) {
      this.dialogVisible = true;
      this.cancelInfo = info;
    },
    closeDialog() {
      this.dialogVisible = false;
      this.cancelInfo = null;
    },
    submitCancelWithdraw() {
      apiCancelWithdraw({ withdrawalId: this.cancelInfo.id })
        .then(result => {
          if (result.data.code == 0) {
            this.closeDialog();
            this.queryRebateHistory(1);
          } else {
            this.showErrorMsg();
          }
        })
        .catch(() => {
          this.showErrorMsg();
        });
    },
    showErrorMsg() {
      this.$message({
        message: this.$t('rebatePaymentHistory.cancelError', {
          supportEmail: this.GLOBAL_CONTACT_EMAIL
        }),
        type: 'error',
        duration: 10000
      });

      this.closeDialog();
      this.queryRebateHistory(1);
    },
    getWithdrawStatus(status, processedByOp) {
      if (status === 11) {
        return !processedByOp ? this.withdrawStatusDesc(2) : this.withdrawStatusDesc(8);
      }
      if (status === 9) {
        return this.withdrawStatusDesc(3);
      }
      // if status is Processing, change it to submitted
      return !processedByOp && (status === 3 || status === 5)
        ? this.withdrawStatusDesc(1)
        : this.withdrawStatusDesc(status);
    },
    getWithdrawStatusColor(status) {
      switch (true) {
        // case [4, 13, 20].includes(status):
        case [4, 13].includes(status):
          return 'red';
        default:
          return 'gray';
      }
    }
  },
  mounted() {
    this.bus.$on('updateRebateHistory', flag => {
      if (flag) {
        this.endDate = new Date().toLocaleString();
        this.initHistory(0);
      }
    });
    this.bus.$on('updateWithdrawHistory', flag => {
      if (flag) {
        this.endDate = new Date().toLocaleString();
        this.initHistory(1);
      }
    });
    this.bus.$on('updateTransferHistory', flag => {
      if (flag) {
        this.endDate = new Date().toLocaleString();
        this.initHistory(2);
      }
    });
    this.startDateDisplay = this.startDate;
    this.endDateDisplay = this.endDate;
  },
  computed: {
    fundsPermission() {
      return this.$store.state.common.permissionIds.includes(3) || !this.$store.state.common.isSubUser;
    },
    timeFrame() {
      return `(${this.$options.filters.formatDate(this.startDate, 'DD-MM-YYYY')}~${this.$options.filters.formatDate(
        this.endDate,
        'DD-MM-YYYY'
      )})`;
    },
    showCancelWithdraw() {
      if (this.withdrawHistoryData.rows === null) {
        return false;
      } else {
        return this.withdrawHistoryData.rows.find(
          // 20220811 UninoPay not support CancelWithdraw (method: 4)
          element =>
            !element.processedByOp &&
            (element.status === 1 || element.status === 3 || element.status === 5) &&
            element.destination !== 4
        );
      }
    },
    rebateStatusDesc() {
      return function(status) {
        const statuses = {
          0: 'rebate.status.processing',
          5: 'rebate.status.processing',
          2: 'rebate.status.processed',
          4: 'rebate.status.rejected'
        };
        return statuses[status];
      };
    },
    withdrawStatusDesc() {
      return function(status) {
        const statuses = {
          1: 'withdraw.status.withdrawalSubmitted',
          2: 'withdraw.status.withdrawalCanceled',
          3: 'withdraw.status.withdrawalAuditing',
          4: 'withdraw.status.withdrawalRejected',
          5: 'withdraw.status.withdrawalAccepted',
          6: 'withdraw.status.withdrawalPayFailed',
          7: 'withdraw.status.withdrawalSuccess',
          8: 'withdraw.status.withdrawalFailed',
          9: 'withdraw.status.withdrawalPending',
          10: 'withdraw.status.withdrawalPartialFailed',
          // WITHDRAW_SUBMIT(1), // 提交中
          // WITHDRAW_CANCEL(2), // 取消
          // WITHDRAW_AUDIT(3), // 审核中
          // WITHDRAW_REFUSE(4), // 拒绝
          // WITHDRAW_ACCEPT(5), // 受理
          // WITHDRAW_PAYFAIL(6), // 打款失败
          // WITHDRAW_SUCCESS(7), // 出金成功
          // WITHDRAW_FAIL(8), // 出金失败
          // WITHDRAW_PENDING(9), // 没有决定的状态，挂着
          // WITHDRAW_HALFFAILED(10); // 部分失败
          // WITHDRAW_REVERSE_FAILED(20,"Reverse processing");
          11: 'withdraw.status.transferFailed',
          12: 'withdraw.status.transferProcessing',
          13: 'withdraw.status.transferProcessing',
          14: 'withdraw.status.transferProcessing',
          15: 'withdraw.status.transferProcessing',
          16: 'withdraw.status.withdrawalSuccess',
          17: 'withdraw.status.withdrawalPartialSuccess',
          18: 'withdraw.status.transferProcessing',
          // 20: 'withdraw.status.withdrawalReversedFailed'
          20: 'withdraw.status.transferProcessing'
          //10+status
          // TRANSFER_SUBMITED(1), // 提交
          // TRANSFER_SUCCESS(2), // 转账成功
          // TRANSFER_REJECT(3), // 审核拒绝
          // TRANSFER_FAIL(4), // 转账失败
          // TRANSFER_PROCESSING(5); // mt4入金成功更新数据库失败--处理中
        };
        return statuses[status];
      };
    },
    withdrawTypeDesc() {
      return function(status) {
        const statuses = {
          //country id: 1, Australia; 2, International bank; 3, China
          2: 'common.withdrawChannel.banktransferinternational',
          3: 'common.withdrawChannel.skrill_neteller',
          4: 'common.withdrawChannel.unionpay',
          5: 'common.withdrawChannel.internetbankingthailand',
          6: 'common.withdrawChannel.internetbankingmalaysia',
          7: 'common.withdrawChannel.fasapay',
          8: 'common.withdrawChannel.internetbankingvietnam',
          9: 'common.withdrawChannel.internetbankingnigeria',
          10: 'common.withdrawChannel.transfer',
          21: 'common.withdrawChannel.aus',
          22: 'common.withdrawChannel.int',
          23: 'common.withdrawChannel.chn',
          24: 'common.withdrawChannel.internetbankingindia', //india
          31: 'common.withdrawChannel.skrill',
          32: 'common.withdrawChannel.neteller',
          34: 'common.withdrawChannel.cryptocurrencybitcoin',
          35: 'common.withdrawChannel.internetbankingkorea',
          36: 'common.withdrawChannel.cryptocurrencyusdt',
          37: 'common.withdrawChannel.bitwallet',
          38: 'common.withdrawChannel.sticpay',
          39: 'common.withdrawChannel.internetbankingindonesia',
          40: 'common.withdrawChannel.internetbankingphilippine',
          42: 'common.withdrawChannel.internetbankingsouthafrica',
          43: 'common.withdrawChannel.internetbankingsouthafrica',
          50: 'common.withdrawChannel.internetbankinguganda',
          51: 'common.withdrawChannel.internetbankingrwanda',
          52: 'common.withdrawChannel.zambiabanktransfer',
          53: 'common.withdrawChannel.congobanktransfer',
          54: 'common.withdrawChannel.internetbankingcameroon',
          55: 'common.withdrawChannel.burundibanktransfer',
          56: 'common.withdrawChannel.internetbankingkenya',
          57: 'common.withdrawChannel.internetbankingghana',
          58: 'common.withdrawChannel.internetbankingtanzania',
          60: 'common.withdrawChannel.internetbankingcanada',
          61: 'common.withdrawChannel.perfectmoney',
          62: 'common.withdrawChannel.internetbankingjapan',
          63: 'common.withdrawChannel.internetbankingmexico',
          64: 'common.withdrawChannel.internetbankingbrazil',
          67: 'common.withdrawChannel.internetbankingnigeria',
          75: 'common.withdrawChannel.ebuy',
          80: 'common.withdrawChannel.cryptocurrencyethcps',
          81: 'common.withdrawChannel.cryptocurrencyusdccps',
          99: 'common.withdrawChannel.interac',
          100: 'PIX',
          102: 'common.withdrawChannel.internetbankingtaiwan',
          108: 'common.withdrawChannel.internetbankinguae',
          109: 'common.withdrawChannel.ewallet',
          110: 'common.withdrawChannel.internetbankingeu',
          999: 'common.withdrawChannel.nigeriabanktransfer'
        };
        return statuses[status];
      };
    },
    regulator() {
      return this.$store.state.common.regulator;
    },
    countryCode() {
      return this.$store.state.common.countryCode;
    }
  },
  components: { Rebate, AccountNumber, DateRangePicker, vDialog, DownloadButton, downloadDialog }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/leads.scss';
@import '@/assets/css/pages/rebatePaymentHistory.scss';
@import '@/assets/css/components/pagination.scss';
</style>
